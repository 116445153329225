import React from 'react'

const Missing = () => {
    return (
        <div className='missing-background'>
            <h2>You have reached to the vincity of a blackhole singularity, run!</h2>
        </div>
    )
}

export default Missing