import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <p>&copy; 2023 Yao. J. All rights reserved.</p>
        </div>
    )
}

export default Footer